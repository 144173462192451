const dev = {
    mapBox: {
        KEY: "pk.eyJ1IjoiYXJ0cGVycGl4ZWwiLCJhIjoiNWIzMGU5ODFhMjczNDg4OGQ0ODU3N2RiYjgxYjg5YTkifQ.4TDvRVpX_pj3efxshpbPfA"
    },
    google:{
        map:{
            KEY:"AIzaSyCzeYg80-rQUC3OnrDg-huj-nJXbn_LqBs"
        },
        analytics:{
            KEY:"UA-150204570-1"
        }
    },
    firebase:{
        apiKey: "AIzaSyCEPgPHtlX_N7uwHsOd8BYQ2Kap1zm-_Rk",
        authDomain: "coseat.firebaseapp.com",
        databaseURL: "https://coseat.firebaseio.com",
        projectId: "coseat",
        storageBucket: "coseat.appspot.com",
        messagingSenderId: "256761566534",
        appId: "1:256761566534:web:154c8ddfe21c50b215c147",
        measurementId: "G-GHVC2MFELF",
        KEY: "BGwe9U9q9nBQTMxGonbmIsnpwOyL_JGGM3GQnqmAe77yx5Vb8oJukx1bjNZDjv5g8XJf4IHYkhKN88Z_4gvjwHI"
    }
};

const prod = {
    mapBox: {
        KEY: "pk.eyJ1IjoiYXJ0cGVycGl4ZWwiLCJhIjoiNWIzMGU5ODFhMjczNDg4OGQ0ODU3N2RiYjgxYjg5YTkifQ.4TDvRVpX_pj3efxshpbPfA"
    },
    google:{
        map:{
            KEY:"AIzaSyCzeYg80-rQUC3OnrDg-huj-nJXbn_LqBs"
        },
        analytics:{
            KEY:"UA-150204570-1UA-150204570-1"
        }
    },
    firebase:{
        apiKey: "AIzaSyCEPgPHtlX_N7uwHsOd8BYQ2Kap1zm-_Rk",
        authDomain: "coseat.firebaseapp.com",
        databaseURL: "https://coseat.firebaseio.com",
        projectId: "coseat",
        storageBucket: "coseat.appspot.com",
        messagingSenderId: "256761566534",
        appId: "1:256761566534:web:154c8ddfe21c50b215c147",
        measurementId: "G-GHVC2MFELF",
        KEY: "BGwe9U9q9nBQTMxGonbmIsnpwOyL_JGGM3GQnqmAe77yx5Vb8oJukx1bjNZDjv5g8XJf4IHYkhKN88Z_4gvjwHI"
    }
};

const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;

export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
