import React from 'react';

function Error_404({ match }) {
    return (
        <div>
            <h2>404 Bulunamadı</h2>
        </div>
    );
}

export default Error_404;
