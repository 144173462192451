var Store = {
    init: (data) => {
        var defaultData = {
            lang:"TR",
            user:{
                isLogged:false,
                token:"",
                data:{
                    id:0,
                    fullName:"",
                }
            },
            people:[],
            settings:{}
        };

        if(!data){
            data = defaultData;
        }

        if(typeof data.lang == "undefined"){
            data = JSON.parse(data);
        }

        var Store = {
          model:data
        };
        Store.listeners = [];

        Store.register = function(listener) {
            Store.listeners.push(listener);
            return listener;
        }
        Store.unregister = function(listener) {
            Store.listeners = [];
        }
        Store.emitChange = function() {
            Store.listeners.forEach(function(listener) {
                listener(Store.model);
            });
            Store.update();
        }
        Store.update = function(data){
          async function asyncFN(){
            await localStorage.setItem('db',JSON.stringify(Store.model));
          }
          asyncFN();
        }
        Store.delete = function(){
          async function asyncFN(){
            let db = await localStorage.removeItem('db');
            await localStorage.setItem('db',JSON.stringify(defaultData));
          }
          asyncFN();
        }

        Store.update();
        return Store;
    }
}

export default Store;
